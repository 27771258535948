import * as React from 'react';
import { Helmet } from 'react-helmet';
import SiteHeader from '@/components/SiteHeader';
import { SiteFooter } from '@/components/SiteFooter';
import { NewsletterSection } from '@/components/sections/NewsletterSection';

const NewsletterPage = () => {
  return (
    <>
      <SiteHeader />
      <main>
        <Helmet>
          <title>Newsletter | Inwave Labs</title>
        </Helmet>
        <NewsletterSection />
      </main>
      <SiteFooter hideNewsletter />
    </>
  );
};

export default NewsletterPage;
